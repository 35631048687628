<template>
  <div class="footer">
    <div class="container">
      <div class="footer-left">
        <ul class="u1">
          <li>
            <span @click="handleLink('/home')">首页</span>
          </li>
          <li>
            <span @click="handleLink('/home#core-technology')">技术</span>
          </li>
          <li>
            <span @click="handleLink('/home#product')">产品</span>
          </li>
          <li>
            <span @click="handleLink('/solution/supplyChain')">解决方案</span>
          </li>
          <li>
            <span @click="handleLink('/news')">新闻资讯</span>
          </li>
          <li>
            <span @click="handleLink('/about')">关于我们</span>
          </li>
          <li class="no-li">
            <span @click="handleLink('/join-us')">加入</span>
          </li>
        </ul>
        <div class="link">联系我们</div>
        <ul class="u2" style="margin-left: 0px">
          <li>
            <span>地址：江苏省南京市鼓楼区清江南路18号鼓楼创新广场B座10楼</span>
          </li>
          <li>
            <span>电话：025-58860124</span>
          </li>
          <li><span>邮箱：info@peerfintech.cn</span></li>
        </ul>
      </div>

      <div class="footer-right">
        <img
          src="@/assets/img/home/footer/ewm-jiangsu.png"
          class="footer-right-saoma"
        />
        <div class="footer-right-text">
          <p>
            <img
              src="@/assets/img/home/footer/wechat.png"
              class="footer-right-icon"
            />扫一扫获得更多
          </p>
          <p>国内国际区块链资讯</p>
        </div>
      </div>
    </div>
    <div class="copy-right">
      Copyright © 2019 江苏众享金联科技有限公司. All Rights Reserved.<a
        href="http://beian.miit.gov.cn/"
        target="_blank"
        class="gong"
        >苏ICP备19062900号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleLink(link) {
      if (this.$route.fullPath === link) {
        if (this.$route.hash) {
          var id = this.$route.hash.substring(1);
          var div = document.getElementById(id);
          div.scrollIntoView();
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        this.$router.push(link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-width {
  width: 1200px;
  margin: 0px auto;
}
.footer {
  background: #252525;
  width: 100%;
  padding: 80px 0 50px;

  .container {
    @extend .content-width;
    display: flex;
    padding-bottom:40px;
    .footer-left {
      width: 66%;
      ul {
        float: left;
        li {
          float: left;
        }
      }

      .u1 li {
        color: #ffffff;
        border-right: 2px solid #fff;
        margin-right: 23px;
        padding-right: 23px;
        font-size: 20px;
        line-height: 28px;
        font-family: "PingFangSC-Medium";
        &:hover {
          transform: scale(1.05);
        }
        a {
          color: #fff;
        }
        &.no-li {
          border: none;
        }
        span {
          cursor: pointer;
        }
      }

      .u2 {
        li {
          float: none;
          margin-top: 10px;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
        }
      }

      .link {
        float: left;
        font-size: 18px;
        margin-top: 48px;
        width: 100%;
        margin-bottom: 10px;
        font-size: 18px;
        font-family: "PingFangSC-Medium";

        color: #ffffff;
        line-height: 25px;
      }
    }
    .footer-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 207px;
      border-left: 1px solid rgba(255, 255, 255, 0.36);
      .footer-right-saoma {
        width: 114px;
        height: 114px;
        margin-right: 20px;
      }
      .footer-right-text {
        color: #ffffff;
        line-height: 25px;
        font-size: 18px;
        font-family: "PingFangSC-Medium";
        .footer-right-icon {
          width: 28px;
          height: 23px;
          vertical-align: middle;
          margin-right: 4px;
        }
      }
    }
  }
  .copy-right {
    border-top: 1px solid rgba(255, 255, 255, 0.16);
    padding-top: 39px;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 22px;
     a.gong {
        color: rgba(255, 255, 255, 0.65);
        margin-left: 32px;
      }
  }
}
</style>
